import React from "react";
import "./styles/App.css";
import { Homepage, ContactPage, GetATutorPage, BecomeATutorPage } from "./screens"
import { URL } from "./constants"
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Link
} from "react-router-dom";


export default function App() {



  return (
    <Router>
      <Switch>
        <Route exact path={URL} element={<Homepage />} />
        <Route exact path={URL + "contact"} element={<ContactPage />} />
        <Route exact path={URL + "tutor"} element={<GetATutorPage />} />
        <Route exact path={URL + "start"} element={<BecomeATutorPage />} />
      </Switch>
    </Router>
  );
}

