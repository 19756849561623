import React, { useState, useEffect } from "react";
import { Header, Footer } from "../components"
import { COLORS, SHADOW, URL } from "../constants"
import "../styles/home.css"
import Tutoring_Img from "../assets/images/tutoring.png"
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import PeopleIcon from '@mui/icons-material/People';
import { WindowSharp } from "@mui/icons-material";

export default function Home(props) {

    function renderStep(number, title, desc, color) {

        let size1 = 4;
        let size2 = 6;

        if (number === STEPS.length) {
            if (number % 2 !== 0) {
                size2 = 12
            }
        }

        if (STEPS.length % 2 !== 0) {
            if (number === STEPS.length - 1 || number === STEPS.length){
                size1 = 6
            }
        } else {
            if (number === STEPS.length){
                size1 = 12
            }
        }
 
        return <div className={"col-md-" + size1 + " col-sm-" + size2} style={{ marginTop: 40 }}>
            <div className="hCentered" style={{ textAlign: 'center' }}>
                <h2 className="wText numbering hCentered vCentered" style={{ backgroundColor: color }}>{number}</h2>
                <h3 style={{ fontWeight: 'bold' }} className="bText">{title}</h3>
                <h4 className="greyText">{desc}</h4>
            </div>
        </div>
    }

    const STEPS = [
        {
            title: 'Our best interests is you',
            desc: "We have a desire to help those who need patience and commitment in grasping their matters at hand",
            color: COLORS.primary
        },
        {
            title: 'A guidance to/in learning',
            desc: "With more than 3 years in professional tutoring, we have met a vast range of students and offer techniques in dealing with their matters at hand.",
            color: COLORS.accent
        },
        {
            title: 'Strong code of ethics',
            desc: "Respect, integrity and professionalism are our drive.",
            color: COLORS.primary
        },
        {
            title: 'Trustworthy in our work',
            desc: `Offering quality education outside of the school environment
            is hard. Yet we see the gap. 1 on 1 learning is very beneficial and
            helps by uplifting  your childs confidence . It is essential to be
            professional in our market and offer the best we can!`,
            color: COLORS.black
        },
        {
            title: 'A life long mentor',
            desc: `Having a tutor that can push your child to succeed is okay.
            Having a mentor for them will guide them through their walk in life.
            We are here for the
            long term outcome of seeing your child succeed.`,
            color: COLORS.grey
        },
    ]

    const [size, setSize] = useState({
        a: 4,
        b: 8
    })

    function resize() {
        if (window.innerWidth > 991) {
            setSize({
                a: 4,
                b: 8
            })
        } else {
            setSize({
                a: 5,
                b: 7
            })
        }
    }

    useEffect(() => {
        document.title = "Luba Tutoring"
        resize()
        window.addEventListener("resize", () => {
            resize()
        })
    }, [])

    return <div>
        <Header active="home" />
        <div className="main-container hCentered" style={{ marginBottom: 100 }}>
            <div className="App desc-wrapper">
                <div className="row">
                    <div className={`col-md-${size.a} vCentered`} style={{ paddingLeft: 0 }}>
                        <h2 className="wText" style={{ fontWeight: 'bold' }}>At Luba tutoring, we guarantee results like protons.</h2>
                        <br />
                        <h4 className="gText">
                            "An investement in knowledge pays the best interest"
                            <br />
                            <br />
                            Benjamin Franklin</h4>
                    </div>
                    <div className={`col-md-${size.b} hCentered`}>
                        <img src={Tutoring_Img} id="img-tutoring" alt="" />
                    </div>
                </div>
            </div>
        </div>

        {/* Services Section */}
        <div className="hCentered">
            <div className="App">
                <div className="row">
                    <div className="col-md-6 hCentered">
                        <div className="flexDiv">
                            <DesktopMacIcon id="pc-icon" style={{ fontSize: 24 }} />
                            <h3 style={{ marginLeft: 15 }}>Online Tutoring</h3>
                        </div>
                    </div>


                    <div className="col-md-6 hCentered services-wrapper">
                        <div className="flexDiv">
                            <PeopleIcon id="people-icon" style={{ fontSize: 24 }} />
                            <h3 style={{ marginLeft: 15 }}>Face to Face Tutoring</h3>
                        </div>
                    </div>
                </div>

                <div className="hCentered" style={{ marginBottom: 80 }}>
                    <h2 style={{ fontWeight: "bold", margin: "100px auto 0px", }} className="bText" >Facts that Make us Unique.</h2>

                    <div className="separator" style={{ marginBottom: 40 }} ></div>

                    <div className="row">
                        {STEPS.map((item, index) => renderStep(index + 1, item.title, item.desc, item.color))}
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>
}