import React from "react";
import { COLORS, URL } from "../constants"
import "../styles/footer.css"
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from "react-router-dom";

export default function Footer(props) {
    return <>
        <div className="footer-topContainer hCentered">
            <div className="App">
                <div className="row" >
                    <div className="col-md-6">
                        <Link to={URL + ""} className="link"><h5>Home</h5></Link>
                        <Link to={URL + "contact"} className="link"><h5>Contact Us</h5></Link>
                        <Link to={URL + "tutor"} className="link"><h5>Get a Tutor</h5></Link>
                        <Link to={URL + "start"} className="link"><h5>Become a Tutor</h5></Link>
                    </div>
                    <div className="col-md-6 footer-right-div">
                        <div className="flexDiv vCentered footer-end-div">
                            <CallIcon className="footer-icon" style={{ fontSize: 20 }} />
                            <a href="tel:0727427419" className="link" style={{ marginLeft: 10 }}><h5>072 742 7419</h5></a>
                        </div>
                        <div className="flexDiv vCentered footer-end-div">
                            <CallIcon className="footer-icon" style={{ fontSize: 20 }} />
                            <a href="tel:0744997909" className="link" style={{ marginLeft: 10 }}><h5>074 499 7909</h5></a>
                        </div>
                        <div className="flexDiv vCentered footer-end-div">
                            <EmailIcon className="footer-icon" style={{ fontSize: 20 }} />
                            <a href="mailto:email@luba-turoting.com" className="link" style={{ marginLeft: 10 }}><h5>tutor@luba-tutoring.co.za</h5></a>
                        </div>
                        <div className="flexDiv vCentered footer-end-div">
                            <a href="#" className="link" target="_blank"><FacebookIcon className="footer-icon" style={{ fontSize: 20, marginLeft: 10 }} /></a>
                            <a href="#" className="link" target="_blank"><InstagramIcon className="footer-icon" style={{ fontSize: 20, marginLeft: 10 }} /></a>
                            <a href="#" className="link" target="_blank"><TwitterIcon className="footer-icon" style={{ fontSize: 20, marginLeft: 10 }} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottomContainer hCentered">
            <div clasName="App" style={{ width: '100%', maxWidth: 1200, margin: "auto 20px" }}>

                <div className="row" style={{ textAlign: 'center' }}>
                    <div className="col-md-4 vCentered">
                        <h5 className="wText" style={{ margin: 0 }}>@ 2022, Luba-Tutoring</h5>
                    </div>
                    <div className="col-md-4 vCentered" >
                        <Link to={URL + ""} className="link">Privacy Policy</Link>
                    </div>
                    <div className="col-md-4 vCentered">
                        <Link to={URL + ""} className="link">Termes and Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}