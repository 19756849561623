import React, { useState, useEffect, useRef } from "react";
import { Header, Footer, Alert } from "../components"
import { COLORS, SHADOW, EMAILJS } from "../constants"
import "../styles/contact.css"
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import BusinessIcon from '@mui/icons-material/Business';
import emailjs from '@emailjs/browser';

export default function Contact(props) {

    useEffect(() => {
        document.title = "Contact Us | Luba Tutoring"
    }, [])

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(EMAILJS.serviceID, 'template_49rw3do', form.current, EMAILJS.userId)
            .then((result) => {
                Alert(true, "Success", "Your message was successfully sent. We'll get in touch with you soon!");
            }, (error) => {
                Alert(false, "Error", "Oops, Something went wrong, please try again!");
            });
    };

    return <div>
        <Header active="contact" />
        <div className="contact-container">
            <div className="contact-wrapper App" style={{padding: 0}}>
                <div className="form-wrapper-contact">
                    <form ref={form} onSubmit={sendEmail}>
                        <input required type="hidden" name="subject" value="Enquiry" />
                        <h5 className="greyText" style={{ marginBottom: 0 }}>Full Name</h5>
                        <input required type="text" placeholder="Enter your full name" name="name" /><br />
                        <h5 className="greyText" style={{ marginBottom: 0 }}>Email Address</h5>
                        <input required type="text" placeholder="Enter your email address" name="email" /><br />
                        <h5 className="greyText" style={{ marginBottom: 0 }}>Message</h5>
                        <textarea type="text" placeholder="Type in your message" name="message" />
                        <button type="submit" style={{ padding: "8px 10px", color: '#fff' }} className="call-to-action">Send Message</button>
                    </form>
                </div>

                <div className="hCentered vCentered address-wrapper">
                    <div className="flexDiv vCentered footer-end-div">
                        <CallIcon className="footer-icon2" style={{ fontSize: 20 }} />
                        <a href="tel:0727427419" className="link-black" style={{ marginLeft: 10 }}><h5 style={{ fontWeight: 'bold' }}>072 742 7419</h5></a>
                    </div>
                    <div className="flexDiv vCentered footer-end-div">
                        <CallIcon className="footer-icon2" style={{ fontSize: 20 }} />
                        <a href="tel:0744997909" className="link-black" style={{ marginLeft: 10 }}><h5 style={{ fontWeight: 'bold' }}>074 499 7909</h5></a>
                    </div>
                    <div className="flexDiv vCentered footer-end-div">
                        <EmailIcon className="footer-icon2" style={{ fontSize: 20 }} />
                        <a href="mailto:email@luba-turoting.com" className="link-black" style={{ marginLeft: 10 }}><h5 style={{ fontWeight: 'bold' }}>tutor@luba-tutoring.co.za</h5></a>
                    </div>
                    <div className="flexDiv vCentered footer-end-div">
                        <BusinessIcon className="footer-icon2" style={{ fontSize: 20 }} />
                        <h5 className="link-black" style={{ fontWeight: 'bold', marginLeft: 10 }}>5 13th avenue. Fairland</h5>
                    </div>
                    <div className="flexDiv vCentered footer-end-div">
                        <a href="#" className="link-black" target="_blank"><FacebookIcon className="footer-icon2" style={{ fontSize: 20, marginLeft: 10 }} /></a>
                        <a href="#" className="link-black" target="_blank"><InstagramIcon className="footer-icon2" style={{ fontSize: 20, marginLeft: 10 }} /></a>
                        <a href="#" className="link-black" target="_blank"><TwitterIcon className="footer-icon2" style={{ fontSize: 20, marginLeft: 10 }} /></a>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>
}