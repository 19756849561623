import React, { useState, useEffect, useRef } from "react";
import { Header, Footer, Alert } from "../components"
import { COLORS, EMAILJS } from "../constants"
import "../styles/contact.css"
import "../styles/getAtutor.css"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import emailjs from '@emailjs/browser';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export default function GetATutor() {

    const OPTIONS = [
        {
            id: 1,
            q1: "Who needs tutoring?",
            res: [{ id: 2, res: "Me" }, { id: 2, res: "My Son" }, { id: 2, res: "My Daughter" }, { id: 2, res: "Someone Else" }],
        },
        {
            id: 2,
            q1: "What do you need assistance with?",
            res: [{ id: 4, res: "University" }, { id: 3, res: "School" }],
        },
        {
            id: 3,
            q1: "What grade is your son in?",
            res: [{ id: 5, res: "Grade 1" }, { id: 5, res: "Grade 2" }, { id: 5, res: "Grade 3" }, { id: 5, res: "Grade 4" }, { id: 5, res: "Grade 5" }, { id: 5, res: "Grade 6" }, { id: 5, res: "Grade 7" }, { id: 5, res: "Grade 8" }, { id: 5, res: "Grade 9" }, { id: 5, res: "Grade 10" }, { id: 5, res: "Grade 11" }, { id: 5, res: "Grade 12" }]
        },
        {
            id: 4,
            q1: "What year is your son?",
            res: [{ id: 5, res: "1st Year" }, { id: 5, res: "2nd Year" }, { id: 5, res: "3rd Year" }, { id: 5, res: "4th Year" }, { id: 5, res: "5th Year" }, { id: 5, res: "Postgrad" }]
        },
        {
            id: 5,
            q1: "What type of lesson do you want?",
            res: [{ id: 5, res: "Online" }, { id: 5, res: "Face-To-Face" }]
        }

    ]

    const form = useRef();
    const numOfQuestions = 4
    const [showLastScreen, setShowLastScreen] = useState(false)
    const [screen, setScreen] = useState(0)
    const [hasFinished, setHasFinished] = useState(false)
    const [response, setResponse] = useState([])
    const [data, setData] = useState([]) // Store temporary data
    const [seek, setSeek] = useState(1 / (numOfQuestions + 1) * 100)

    useEffect(() => {
        document.title = "Get A Tutor | Luba Tutoring"
        setData([OPTIONS[0]])
    }, [])

    function responseCard(d) {

        const title = d?.res || d
        const id = d?.id

        return <div onClick={() => {

            const res = response;
            const doc = { title: title, q: data[screen].q1 }
            res[screen] = doc

            setResponse(res)

            sortData(screen, id)

            onNextClicked(null)

        }} className={"responseCard" + (response[screen]?.title === title ? " active-card" : "")}>
            <h4 className="lgText" style={{ margin: 0, fontWeight: 'bold', transition: "1s" }}>{title}</h4>
        </div>
    }

    function onGoBackClicked() {
        if (!showLastScreen)
            setScreen(prev => {
                return prev - 1 >= 0 ? prev - 1 : 0
            })

        else
            setShowLastScreen(false);

        setSeek((prev) => {
            return prev > 0 ? prev - (1 / (numOfQuestions + 1)) * 100 : 0
        })
    }

    function onNextClicked(e) {
        if (e !== null)
            e.preventDefault();

        let total = 0;
        setScreen(prev => {
            if (prev + 1 < numOfQuestions)
                total = prev + 1
            else {
                total = numOfQuestions - 1
                setShowLastScreen(true);
            }

            return total
        })

        setSeek((prev) => {
            let perc = prev + (1 / (numOfQuestions + 1)) * 100
            return perc < 100 ? perc : 100 + 0.0000000001
        })
    }


    function sortData(index, id) {
        let doc = {}

        for (let i = 0; i < OPTIONS.length; i++) {
            if (OPTIONS[i].id === id) {
                doc = OPTIONS[i]
                break;
            }
        }

        const docs = data
        docs[index + 1] = doc
        setData(docs)
    }

    function getData(data) {
        let text = ""
        for (let i = 0; i < data.length; i++) {
            text += "Question " + (i + 1).toString() + "<br />" + data[i].q + "<br />" + "Answer: " + data[i].title + "<br />" + "<br />"
        }

        return text
    }

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(EMAILJS.serviceID, 'template_zjwgety', form.current, EMAILJS.userId)
            .then((result) => {
                setHasFinished(true)
            }, (error) => {
                Alert(false, "Error" ,"Oops, Something went wrong, please try again!");
            });
    };

    function reset(){
        setHasFinished(false)
        setShowLastScreen(false)
        setScreen(0)
        setResponse([])
        setData([OPTIONS[0]])
        setSeek(1 / (numOfQuestions + 1) * 100)
    }


    return <div>
        <Header active="tutor" />
        <div className="contact-container">
            <div className="form-wrapper">
                {!hasFinished ? <form ref={form} onSubmit={sendEmail}>
                    <input required type="hidden" name="subject" value="Hiring a Tutor" />

                    {!showLastScreen ? <h2>{data[screen]?.q1}</h2> : <h2>How do we contact you?</h2>}
                    {showLastScreen && <><h4 className="greyText">We’ll get in touch to send you tutor options, fees and advice based on your needs.</h4><br /></>}
                    <div className="seek">
                        <div className="seek" style={{ backgroundColor: COLORS.secondary, transition: "1s", width: `${seek}%` }}></div>
                    </div>
                    {!showLastScreen && data[screen]?.res.map((item, index) => responseCard(item, index))}


                    {/* Last Screen */}
                    {showLastScreen && <>
                        <h5 className="greyText" style={{ marginBottom: 0 }}>Your Name</h5>
                        <input required type="text" placeholder="Enter your full name" name="name" /><br />

                        <h5 className="greyText" style={{ marginBottom: 0 }}>Learner's Name</h5>
                        <input required type="text" placeholder="Enter the learner's name" name="lname" /><br />

                        <h5 className="greyText" style={{ marginBottom: 0 }}>Email Address</h5>
                        <input required type="text" placeholder="Enter your email address" name="email" /><br />

                        <h5 className="greyText" style={{ marginBottom: 0 }}>Phone Number</h5>
                        <input required type="text" placeholder="Enter your phone number" name="phone" /><br />
                        <input required type="hidden" name="data" value={getData(response)} />

                        {(() => {
                            let blContinue = false;

                            for (let i = 0; i < response.length; i++) {
                                if (response[i].title === "Face-To-Face") {
                                    blContinue = true;
                                    break;
                                }
                            }

                            if (blContinue)
                                return <>
                                    <h5 className="greyText" style={{ marginBottom: 0 }}>Residential Address</h5>
                                    <input required type="text" placeholder="Enter your resendetial adress" name="address" /><br />
                                </>
                            else
                                return null;
                        })()}

                        <h5 className="greyText" style={{ marginBottom: 0 }}>Subject(s)</h5>
                        <input required type="text" placeholder="Enter the subject(s) (Separate them with commas)" name="school-subject" /><br />

                        <h5 className="greyText" style={{ marginBottom: 0 }}>Description</h5>
                        <textarea type="text" placeholder="Enter a brief description about the learner (Optional)" name="desc" /><br />
                    </>}

                    <div className="flexDiv" style={{ justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
                        {screen !== 0 && <div onClick={onGoBackClicked} className="flexDiv btn-back" style={{ alignItems: "center" }}>
                            <ArrowBackIosIcon style={{ fontSize: 15, color: COLORS.secondary }} />
                            <h4 className="gText" >Back</h4>
                        </div>}

                        {(data[screen]?.res.includes(response[screen]?.title) || showLastScreen) && <button onClick={!showLastScreen ? onNextClicked : null} type={!showLastScreen && "submit"} style={{ padding: "8px 25px", color: '#fff' }} className="call-to-action">{!showLastScreen ? "Next" : "Submit"}</button>}
                    </div>
                </form> :
                    <div className="vCentered hCentered" style={{textAlign: 'center' }}>
                        <h2>Well-done</h2><br />
                        <h4 className="greyText">Your query has been successfully sent. <br /> We'll get in touch with you soon!</h4><br /><br />

                        <DoneAllIcon id="pc-icon" style={{ fontSize: 24 }} /><br /><br />
                        <button onClick={reset} style={{ padding: "8px 25px", color: '#fff' }} className="call-to-action">Send a new enquiry</button>
                    </div>
                }
            </div>
        </div>

        <Footer />
    </div>
}