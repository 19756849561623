import React, { useState, useEffect } from "react";
import { ICONS, SHADOW, URL } from "../constants"
import { Link } from "react-router-dom";
import "../styles/navbar.css"

export default function Header({ active }) {

    const [isFixed, setIsFixed] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    function checkIsScrolled() {
        if (window.scrollY > 10)
            setIsFixed(true);
        else
            setIsFixed(false);
    }

    const fixedStyles = {
        ...SHADOW,
        position: 'fixed',
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("scroll", () => {
            checkIsScrolled()
        })
    }, [])

    return <nav id="navBar" class="navbar navbar-expand-lg" style={(isFixed || isExpanded) ? fixedStyles : {}}>
        <div class="container-fluid App">
            <Link class="navbar-brand" to={URL + ""} ><img src={ICONS.Logo} style={{ height: isFixed ? 40 : 100, transition: "0.9s" }} /></Link>
            <div></div>
            <button onClick={() => setIsExpanded(!isExpanded)} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <Link class={"nav-link" + (active === "home" ? " active" : "")} aria-current="page" to={URL + ""} >Home</Link>
                    </li>
                    <li class="nav-item">
                        <Link class={"nav-link" + (active === "contact" ? " active" : "")} to={URL + "contact"} >Contact Us</Link>
                    </li>
                    <li class="nav-item">
                        <Link class={"nav-link" + (active === "start" ? " active" : "")} to={URL + "start"} >Become a Tutor</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link call-to-action" to={URL + "tutor"} >Get a Tutor</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
}