import Logo from "../assets/images/Logo.png";

const COLORS = {
    primary: "#96B59E",
    secondary: "#3A6E47",
    accent: "#fb4b74",
    white: "#FFFFFF",
    black: "#000",
    grey: "#6d6d6d"
}

const SHADOW = {
    boxShadow: "0px 0px 20px " + COLORS.grey
}

const ICONS = {
    Logo
}

const URL = "/"

const EMAILJS = { 
    userId: "user_4lvBDg9fDuqi7swexXZvl",
    serviceID: "service_3fssi5s"
}

export {COLORS, SHADOW, URL, EMAILJS, ICONS}