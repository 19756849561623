import React, { useState, useEffect, useRef } from "react";
import { Header, Footer, Alert } from "../components"
import { COLORS, SHADOW, URL } from "../constants"
import "../styles/contact.css"
import emailjs from '@emailjs/browser';

export default function BecomeATutor(props) {

    useEffect(() => {
        document.title = "Become A Tutor | Luba Tutoring"
    }, [])

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm("service_6wqmctl", 'template_yism8n7', form.current, "user_qML4COmBODfnXcY4KtAtV")
            .then((result) => {
                Alert(true, "Success", "Your application was successfully sent. We'll get in touch with you soon!");
            }, (error) => {
                Alert(false, "Error", "Oops, Something went wrong, please try again!");
            });
    };


    return <div>
        <Header active="start" />
        <div className="contact-container">
            <div className="form-wrapper">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="hidden" name="subject" value="Tutoring Application" />
                    <h5 className="greyText" style={{ marginBottom: 0 }}>Full Name</h5>
                    <input required type="text" placeholder="Enter your full name" name="name" /><br />
                    <h5 className="greyText" style={{ marginBottom: 0 }}>Email Address</h5>
                    <input required type="text" placeholder="Enter your email address" name="email" />

                    <h5 className="greyText" style={{ marginBottom: 0 }}>Phone Number</h5>
                    <input required type="text" placeholder="Enter your phone number" name="phone" /><br />

                    <h5 className="greyText" style={{ marginBottom: 0 }}>Residential Address</h5>
                    <input required type="text" placeholder="Enter your resendetial adress" name="address" /><br />

                    <h5 className="greyText" style={{ marginBottom: 0 }}>Subject(s)</h5>
                    <input required type="text" placeholder="Enter the subject(s) you would like to teach (Separate them with commas)" name="school-subject" /><br />

                    <h5 className="greyText" style={{ marginBottom: 0 }}>Highest Level of Study</h5>
                    <input required type="text" placeholder="Enter your Highest level of study" name="level" /><br />

                    <h5 className="greyText" style={{ marginBottom: 0 }}>Do You Have a Driving License</h5>
                    <input required type="text" placeholder="Answer Yes or No" name="license" /><br />

                    {/* <h5 className="greyText" style={{ marginBottom: 0 }}>Upload School Certificate</h5>
                    <input required type="file" placeholder="Answer Yes or No" name="certificate" /><br /><br />

                    <h5 className="greyText" style={{ marginBottom: 0 }}>Upload Driving License</h5>
                    <input required type="file" placeholder="Answer Yes or No" name="driving" /><br /><br /> */}

                    <button type="submit" style={{ padding: "8px 10px", color: '#fff' }} className="call-to-action">Apply</button>
                </form>
            </div>
        </div>

        <Footer />
    </div>
}